import React, { useState } from 'react'
import Layout from '../components/Layout/Layout'
import { Link } from 'react-router-dom';
import toast  from 'react-hot-toast';
import axios from 'axios';
import { useNavigate , useLocation} from 'react-router-dom';
import { useAuth } from '../context/auth';
import config from '../config'

const Login = () => {

  const [email , SetEmail]  = useState('');
  const [password , SetPassword] = useState('')
  const [auth, setAuth] = useAuth();
  const navigate = useNavigate()
  const location = useLocation()

   const loginHandler =  async (e) => {
      e.preventDefault()
       try {
         
        const res = await axios.post(`${config.baseURL}/api/login`, {email , password});


        if(res.data.success)
        {

            setTimeout(() => {
                toast.success(res.data.message, {
                  autoClose: 10000, // Adjust the auto-close time if needed
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                });
              }, 2000); 

           setAuth({
            ...auth,
            user:res.data.user,
            token :res.data.token
           }) 

           localStorage.setItem('auth',JSON.stringify(res.data));

            navigate( location.state ||'/dashboard/admin')

        }else {

            setTimeout(() => {
                toast.error(res.data.message, {
                  autoClose: 10000, // Adjust the auto-close time if needed
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                });
              }, 2000); 
           
        }

       } catch (error) {
        
        toast.error('Something went wrong');
       }
   }
  return (
    <Layout title={"login - imExchanges"}>

   <section className='register__area section-space'>
    <div className='container'>
    <div className='login__wrapper text-center mx-auto'>
    <div className="login__intro mb-40">       
        <h3 className="login__title">Login Your account</h3>
    </div>
       <form onSubmit={loginHandler}>
            <div className="login__input mb-25">
                <input type="email" id="form2Example1" style={{color:'white'}} value={email}  onChange={(e) => SetEmail(e.target.value)} placeholder='Enter Email' className="form-control" />
            </div>

                <div className="login__input mb-25">
                    <input type="password" id="form2Example2" style={{color:'white'}} value={password} onChange={(e) => SetPassword(e.target.value)} placeholder='Enter Password' className="form-control" />
                </div>


                    <button type="submit" className="bd-gradient-btn w-100">Sign in</button>

                 
                    <div className="text-center">
                        <p><Link to='/forgot-password' style={{color:'white'}}>Forgot Password </Link></p>
                    </div>
        </form>
        </div>
        </div>
        </section>
    </Layout>
  )
}

export default Login
