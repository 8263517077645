import React, { useEffect } from 'react'
import { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
const Spinner = () => {
    const [count , setCount] = useState(5)
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(()=>{
        const interval = setInterval(()=>{
            setCount((pre)=> --pre)
        }, 1000)

        count === 0 && navigate('/login', {
            state: location.pathname
        })

        return () => clearInterval(interval)
    }, [count , navigate , location])

  

  return (
    <>
       <div className="d-flex align-items-center justify-content-center" style={{marginTop:'20%'}}>
      <div>
      <h5>UnAuthorized Access, we Will Redirect in {count} second </h5>
      </div>
      <div className="spinner-border" role="status">
      <span className="visually-hidden">Loading...</span>
    
       </div>
      </div>
    </>
  )
}

export default Spinner
