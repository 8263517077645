 import { Link } from 'react-router-dom'
 import React, { useState, useEffect } from 'react';
 import axios from 'axios';
 import config from '../config';
import { useAuth } from '../context/auth';
import toast from 'react-hot-toast';
 const AdminDashboard = () => {

  const [auth ,setAuth]  =  useAuth()

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    fetchData();
    //eslint-disable-next-line
  }, [currentPage]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${config.baseURL}/api/buy-data?page=${currentPage}`);
      setData(response.data.data);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      alert('Something went wrong');
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm(`Are you sure you want to delete this item?`)) {
      try {
        await axios.delete(`${config.baseURL}/api/buy-data/${id}`);
        fetchData();
      } catch (error) {
        alert('Error deleting item');
      }
    }
  };

  const logoutHandler = () => {
    setAuth({
      ...auth,
      user:null,
      token:''
  })
  
  setTimeout(() => {
      toast.success('logout Successfully', {
          autoClose: 8000, // Adjust the auto-close time if needed
          hideProgressBar: false,
          pauseOnHover: true,
          draggable: true,
      });
  }, 2000); 
  
  localStorage.removeItem('auth')
  }
    const footer = {
        backgroundColor: '#2C1737',
        padding: '20px 0',
        textAlign: 'center',
        position: 'fixed',
        left: 0,
        bottom: 0,
        width: '100%',
        display: 'flex',
        alignItems: 'center'
      };
      
   return (
     <>
    <nav className="navbar navbar-expand-lg navbar-light" style={{backgroundColor:"#2C1737" ,height:'100px'}}>
    <Link class="navbar-brand" to="/dashboard">
    <img src="./imgs/logo/logo.png" style={{height:'150px'}} class="d-inline-block align-top" alt="Your Logo" />
  </Link>
  {/* <img className="navbar-brand" src="./imgs/logo/logo.png" alt='logo not found' style={{height:'1re,', width:'1rem'}}></img> */}
  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon"></span>
  </button>

  <div className="collapse navbar-collapse justify-content-center" id="navbarSupportedContent">
  <ul className="navbar-nav mr-auto" style={{ marginRight: '20px'}}>
  <li className="nav-item active">
    <Link className="nav-link" to="/dashboard/admin" style={{ color: 'white', padding: '10px' }}>Dashboard </Link>
  </li>
  <li className="nav-item">
    <Link className="nav-link" to="/dashboard/Contact-data" style={{ color: 'white', padding: '10px' }}>Contact Data</Link>
  </li>
  <li className="nav-item">
    <Link className="nav-link" to="/dashboard/Buy-data" style={{ color: 'white', padding: '10px' }}>Buying Data</Link>
  </li>
  <li className="nav-item">
    <Link className="nav-link" to="/dashboard/Sell-data" style={{ color: 'white' , padding: '10px'}}>Selling Data</Link>
  </li>

  <li className="nav-item">
    <Link className="nav-link" to="/login" onClick={logoutHandler} style={{ color: 'white', padding: '10px' }}> logout</Link>
  </li>
  {/* <!-- Add more menu items as needed --> */}
</ul>

  </div>
</nav>


  {/* React By Share  */}

  <div className="container" style={{ marginTop: '7rem' }}>
        <h4> Recent Buy Share</h4>
        <div className="table-responsive" style={{ marginTop: '3rem' }}>
          <table className="table table-striped table-sm">
            <thead className='thead-dark'>
              <tr>
              <th scope="col" className='text-white'>First Name</th> 
                <th scope="col" className='text-white'>last Name</th> 
                <th scope="col" className='text-white'>Email</th>
                <th scope="col" className='text-white'>Phone</th>
                <th scope="col" className='text-white'>Company Name</th>
                <th scope="col" className='text-white'>Price</th>
                <th scope="col" className='text-white'> NumberOfShare</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item) => (
                <tr key={item._id}>
                <td className='text-white'>{item.Fname}</td>
                  <td className='text-white'>{item.Lname}</td>
                  <td className='text-white'>{item.email}</td>
                  <td className='text-white'>{item.phone}</td>
                  <td className='text-white'>{item.companyName}</td>
                  <td className='text-white'>{item.price}</td>
                  <td className='text-white'>{item.NumberOfShare}</td>
                  <td>
                    <button className="btn btn-danger" onClick={() => handleDelete(item._id)}>Delete</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px',marginBottom: '20px' }}>
          <button className="btn btn-primary " onClick={handlePreviousPage}  style={{ marginRight: '10px' }} disabled={currentPage === 1}>Previous</button>
          <span style={{ margin: '0 10px' }}>{currentPage}</span>
          <button className="btn btn-primary" onClick={handleNextPage}  style={{ marginRight: '10px' }} disabled={currentPage === totalPages}>Next</button>
        </div>
      </div>







<footer className="footer" style={footer}>
  <div className="container">
    <span className="text-muted">© 2024 Incubation Master</span>
  </div>
</footer>


     </>
   )
 }

 
export { AdminDashboard }
