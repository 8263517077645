import React from 'react'
import Layout from '../components/Layout/Layout'

const PagesNotFound = () => {
  return (
    <Layout title={"Page Not Found - ImExchanges"}>
       <p> 404 pages not found</p>
    </Layout>
  )
}

export default PagesNotFound
