import React from 'react'
import Header from './Header'
import Footer from './Footer'
import {Helmet} from "react-helmet";
import  { Toaster } from 'react-hot-toast';


const Layout = ({children, title , description, keywords}) => {
  return (
    <div>
    <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content="{description}" />
        <meta name="keywords" content="{keywords}" />
    </Helmet>
      <Header />
     <main>
     {children}

     <Toaster />
     </main>
    <Footer />
    </div>
  )
}

export default Layout
