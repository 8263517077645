import React from 'react'
import Layout from '../components/Layout/Layout'
import { Link } from 'react-router-dom'
const UnlistedShare = () => {
  return (
    <Layout title={"Unlisted Share - imExchanges"} description={" ImExchange provides a curated selection of listed startup companies for investors to explore and invest in. Discover exciting opportunities to diversify your portfolio and support the growth of innovative startups. Explore the listed startup companies on ImExchange and start investing in the future today."} keywords={"listed startup companies, startup shares, ImExchange, invest in startups, startup stock exchange"}>
      
      {/* <!-- Breadcrumb area start  --> */}
        <div className="breadcrumb__area breadcrumb-space theme-bg-1 valign p-relative z-index-11 fix">
            <div className="breadcrumb__glow">
                <div className="glow-1"></div>
                <div className="glow-2"></div>
            </div>
            <div className="round__shape">
                <img src="/imgs/shapes/cercle.png" alt="img not found" />
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-xxl-6 col-xl-6 col-lg-8">
                        <div className="breadcrumb__title-wraper">
                            <span className="section__subtitle-7 wow fadeIn mb-20" data-wow-delay=".3s">About Share</span>
                            <h2 className="breadcrumb__title wow fadeIn" data-wow-delay=".5s"> <span>Unlisted Share</span> </h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- Breadcrumb area start  --> */}


        
        {/* <!-- Offer unlisted price  start --> */}
        <section className="offer__area section-space">
            <div className="container">
                <div className="row section__title-space justify-content-center">
                    <div className="col-xxl-5 col-xl-6 col-lg-6 col-md-8 col-sm-10">
                        <div className="section__title-wrapper text-center">
                            <span className="section__subtitle-5 uppercase mb-20">our Unlisted Company</span>
                             <h2 className="section__title">Unlisted Shares</h2> 
                        </div>
                    </div>
                </div>
                <div className="row gy-5">
                    <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6">
                        <div className="offer__item wow fadeInUp" data-wow-delay=".3s">
                            <div className="offer__icon">
                                <span><img src="/imgs/logo/com1.png" alt="img not found" /></span>
                            </div>
                            <div className="offer__content">
                                <h3><a href="/About-Company-Details">Energies Limited Unlisted Shares</a></h3>
                                <span>share price - 20.5</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6">
                        <div className="offer__item is-mainly-red wow fadeInUp" data-wow-delay=".5s">
                            <div className="offer__icon">
                                <span><img src="/imgs/logo/bira.png" alt="img not found" /></span>
                            </div>
                            <div className="offer__content">
                                <h3><a href="/About-Company-Details">B9 Beverages Pvt Ltd Unlisted Shares</a></h3>
                                <span>Share Price - 50.0</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6">
                        <div className="offer__item is-mainly-blue wow fadeInUp" data-wow-delay=".7s">
                            <div className="offer__icon">
                                <span><img src="/imgs/logo/com2.png" alt="img not found" /></span>
                            </div>
                            <div className="offer__content">
                                <h3><a href="/About-Company-Details">Capgemini Technology SIL Unlisted Shares</a></h3>
                                <span>Share Price - 30.40</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6">
                        <div className="offer__item is-mainly-pink wow fadeInUp" data-wow-delay=".9s">
                            <div className="offer__icon">
                                <span><img src="/imgs/logo/com3.png" alt="img not found" /></span>
                            </div>
                            <div className="offer__content">
                                <h3><a href="/About-Company-Details">Merino Industries Ltd Unlisted Shares</a></h3>
                                <span>Share Price - 20.40</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row gy-5" style={{marginTop: "50px"}}>
                    <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6">
                        <div className="offer__item wow fadeInUp" data-wow-delay=".3s">
                            <div className="offer__icon">
                                <span><img src="/imgs/logo/com4.png" alt="img not found"  /></span>
                            </div>
                            <div className="offer__content">
                                <h3><Link to="#">Philips India Ltd Unlisted Share</Link></h3>
                                <span>share price - 40.5</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6">
                        <div className="offer__item is-mainly-red wow fadeInUp" data-wow-delay=".5s">
                            <div className="offer__icon">
                                <span><img src="./imgs/logo/con5.png" alt="img not found" /></span>
                            </div>
                            <div className="offer__content">
                                <h3><Link to="/About-Company-Details">Hexa Technologies Ltd Unlisted Share</Link></h3>
                                <span>Share Price - 60.0</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6">
                        <div className="offer__item is-mainly-blue wow fadeInUp" data-wow-delay=".7s">
                            <div className="offer__icon">
                                <span><img src="./imgs/logo/con6.png" alt="img not found" /></span>
                            </div>
                            <div className="offer__content">
                                <h3><Link to="/About-Company-Details">ESL Steel Limited Unlisted Shares</Link></h3>
                                <span>Share Price - 40.40</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6">
                        <div className="offer__item is-mainly-pink wow fadeInUp" data-wow-delay=".9s">
                            <div className="offer__icon">
                                <span><img src="/imgs/logo/com3.png" alt="img not found" /></span>
                            </div>
                            <div className="offer__content">
                                <h3><Link to="/About-Company-Details">Fincare Small Finance Bank Unlisted Shares</Link></h3>
                                <span>Share Price - 20.40</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- Offer area end --> */}













    </Layout>
  )
}

export default UnlistedShare
