import React from 'react'

import Layout from '../components/Layout/Layout';
import { Link } from 'react-router-dom';

// import { useAuth } from '../context/auth';



const HomePages = () => {
   
    // const [auth] = useAuth();

  return (
    <Layout title={"Home - ImExchanges"} description={"ImExchange is your premier destination for buying and selling shares of exciting startups. Discover and invest in the next big thing, or divest your stake in burgeoning companies. Join ImExchange today and be part of the future of investing."} keywords={"startup shares, buy and sell shares, invest in startups, ImExchange, startup investing platform, innovative startups, buy startup stocks, sell startup equity"}>
    {/* <pre>{JSON.stringify(auth , null , 4)}</pre> */}
  
     {/* <!-- Body glow start --> */}
    <div className="glow__area">
        <div className="body__color-glow"></div>
        <div className="body__color-glow glow-2"></div>
    </div>
    {/* <!-- Body glow end --> */}

    {/* <!-- Body main wrapper start --> */}
    {/* <main> */}

        {/* <!-- Banner area start --> */}
        <section className="banner__area banner-6 p-relative z-index-11 x-clip">
            <div className="banner__glow-6">
                <div className="glow-1 d-none d-md-block"></div>
                <div className="glow-2"></div>
            </div>
            <div className="container">
                <div className="row gy-50 align-items-center">
                    <div className="col-xxl-6 col-xl-6 col-lg-7 col-md-6">
                        <div className="banner__content-5 wow fadeInLeft animated" data-wow-delay=".6s">
                            <span className="banner__subtitle-5 uppercase mb-25">Investment Excellence</span>
                            <h2 className="banner__title-5">Investment for Financial Growth.</h2>
                            <p> This insightful guide explores the dynamic world of investments, offering practical strategies and expert advice to help individuals achieve sustainable financial growth. From understanding different investment vehicles to navigating market fluctuations, this resource empowers readers to make informed decisions and build a robust portfolio tailored to their goals. </p>
                            <div className="btn__wrapper">
                                <div className="banner__btn-group">
                                    <Link className="bd-gradient-btn" to="/Contact">Get Started<span><i className="fa-regular fa-angle-right"></i></span></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-5 col-md-6">
                        <div className="banner__thumb-wrapper-5 d-flex justify-content-end wow fadeInRight animated" data-wow-delay=".6s">
                            <div className="banner__thumb-5">
                                <img src="./imgs/banner/banner-06.png" alt="img not found" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- Banner area end --> */}

       



        {/* <!-- Offer unlisted price  start --> */}
        <section className="offer__area section-space">
            <div className="container">
                <div className="row section__title-space justify-content-center">
                    <div className="col-xxl-5 col-xl-6 col-lg-6 col-md-8 col-sm-10">
                        <div className="section__title-wrapper text-center">
                            <span className="section__subtitle-5 uppercase mb-20">our Offers</span>
                            <h2 className="section__title">Unlisted Shares</h2>
                        </div>
                    </div>
                </div>
                <div className="row gy-5">
                    <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6">
                        <div className="offer__item wow fadeInUp" data-wow-delay=".3s">
                            <div className="offer__icon">
                                <span><img src="/imgs/logo/com1.png" alt="" /></span>
                            </div>
                            <div className="offer__content">
                                <h3><Link to="/About-Company-Details">Energies Limited Unlisted Shares</Link></h3>
                                <span>share price - 240.5</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6">
                        <div className="offer__item is-mainly-red wow fadeInUp" data-wow-delay=".5s">
                            <div className="offer__icon">
                                <span><img src="/imgs/logo/bira.png" alt="" /></span>
                            </div>
                            <div className="offer__content">
                                <h3><Link to="/About-Company-Details">B9 Beverages Pvt Ltd Unlisted Shares</Link></h3>
                                <span>Share Price - 660.0</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6">
                        <div className="offer__item is-mainly-blue wow fadeInUp" data-wow-delay=".7s">
                            <div className="offer__icon">
                                <span><img src="/imgs/logo/com2.png" alt="" /></span>
                            </div>
                            <div className="offer__content">
                                <h3><Link to="/About-Company-Details">Capgemini Technology SIL Unlisted Shares</Link></h3>
                                <span>Share Price - 340.40</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6">
                        <div className="offer__item is-mainly-pink wow fadeInUp" data-wow-delay=".9s">
                            <div className="offer__icon">
                                <span><img src="/imgs/logo/com3.png" alt="" /></span>
                            </div>
                            <div className="offer__content">
                                <h3><Link to="/About-Company-Details">Merino Industries Ltd Unlisted Shares</Link></h3>
                                <span>Share Price - 200.40</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row gy-5" style={{marginTop: "50px"}}>
                    <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6">
                        <div className="offer__item wow fadeInUp" data-wow-delay=".3s">
                            <div className="offer__icon">
                                <span><img src="/imgs/logo/com4.png" alt="" /></span>
                            </div>
                            <div className="offer__content">
                                <h3><Link to="/About-Company-Details">Philips India Ltd Unlisted Share</Link></h3>
                                <span>share price - 240.5</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6">
                        <div className="offer__item is-mainly-red wow fadeInUp" data-wow-delay=".5s">
                            <div className="offer__icon">
                                <span><img src="/imgs/logo/con5.png" alt="Company Logo" /></span>
                            </div>
                            <div className="offer__content">
                                <h3><Link to="/About-Company-Details">Hexa Technologies Ltd Unlisted Share</Link></h3>
                                <span>Share Price - 660.0</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6">
                        <div className="offer__item is-mainly-blue wow fadeInUp" data-wow-delay=".7s">
                            <div className="offer__icon">
                                <span><img src="/imgs/logo/con6.png" alt="Company Logo" /></span>
                            </div>
                            <div className="offer__content">
                                <h3><Link to="/About-Company-Details">ESL Steel Limited Unlisted Shares</Link></h3>
                                <span>Share Price - 340.40</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6">
                        <div className="offer__item is-mainly-pink wow fadeInUp" data-wow-delay=".9s">
                            <div className="offer__icon">
                                <span><img src="/imgs/logo/com3.png" alt="Company Logo"/></span>
                            </div>
                            <div className="offer__content">
                                <h3><Link to="/About-Company-Details">Fincare Small Finance Bank Unlisted Shares</Link></h3>
                                <span>Share Price - 200.40</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- Offer area end --> */}


        




      

        {/* <!-- Trade area start --> */}
        <section className="trade__area section-space">
            <div className="container">
                <div className="row gy-50 align-items-center">
                    <div className="col-xxl-6 col-xl-6 col-lg-6">
                        <div className="trade__thumb-wrapper wow fadeInLeft animated" data-wow-delay=".6s">
                            <div className="trade__thumb">
                                <img src="/imgs/trade/trade-01.png" alt="img not found" />
                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6">
                        <div className="trade__content wow fadeInUp" data-wow-delay=".9s">
                            <div className="section__title-wrapper mb-30">
                                <span className="section__subtitle-5 uppercase mb-20">Trade Now</span>
                                <h2 className="section__title">Discover a Better way to Trade.</h2>
                            </div>
                            <p className="mb-30">Unlock a new dimension of trading with imExchange. Discover a better way to trade startup company stock, where innovation meets opportunity. Whether you're a seasoned investor seeking to diversify your portfolio or a newcomer looking to explore the world of startups, imExchange offers a seamless and secure platform for buying and selling.</p>
                            <div className="btn__wrapper">
                                <Link className="bd-gradient-btn" to="/About">About Us<span><i className="fa-regular fa-angle-right"></i></span></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- Trade area end --> */}

        

        {/* <!-- Feedback area start --> */}
        <section className="feedback__area p-relative z-index-11 section-space-bottom">
            <div className="feedback__six-bg theme-bg-1"></div>
            <div className="container">
                <div className="feedback__wrapper-6">
                    <div className="feedback__thumb-6">
                        <img src="./imgs/user/user-07.png" alt="img not found" />
                    </div>
                    <div className="inner">
                        <div className="swiper feedback__active-6">
                            <div className="swiper-wrapper">
                                <div className="swiper-slide">
                                    <div className="feedback__item-6">
                                        <div className="feedback__content-6">
                                            <div className="feedback__author">
                                                <div className="feedback__author-info-5 mb-5">
                                                    <h3>Maral Arman</h3>
                                                    <span>Business man</span>
                                                </div>
                                            </div>
                                            <div className="feedback__rating">
                                                <span><i className="fa-solid fa-star"></i></span>
                                                <span><i className="fa-solid fa-star"></i></span>
                                                <span><i className="fa-solid fa-star"></i></span>
                                                <span><i className="fa-solid fa-star"></i></span>
                                                <span><i className="fa-solid fa-star"></i></span>
                                            </div>
                                            <p>"imExchange has simplified my startup investing journey. The platform is user-friendly, and the curated selection of companies makes it easy to find promising opportunities."</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="feedback__item-6">
                                        <div className="feedback__content-6">
                                            <div className="feedback__author">
                                                <div className="feedback__author-info-5 mb-5">
                                                    {/* <h3>Eiola J. Farr</h3>
                                                    <span>Business man</span> */}
                                                </div>
                                            </div>
                                          
                                            {/* <p>"I appreciate the transparency and security measures implemented by imExchange. It gives me peace of mind knowing that my investments are safe."</p> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="feedback__item-6">
                                        <div className="feedback__content-6">
                                            <div className="feedback__author">
                                                <div className="feedback__author-info-5 mb-5">
                                                    {/* <h3>Maral Arman</h3>
                                                    <span>Business man</span> */}
                                                </div>
                                            </div>
                                         
                                            {/* <p>"imExchange has helped me diversify my investment portfolio with startup stocks. It's exciting to be part of the innovation happening in the startup world."</p> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="pagination__wrappper">
                                <div className="bd-swiper-dot is-white"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- Feedback area end --> */}

        {/* <!-- FAQ area start --> */}
        <section className="faq__area section-space-bottom">
            <div className="container">
                <div className="row section__title-space justify-content-center">
                    <div className="col-xxl-5 col-xl-5 col-lg-5">
                        <div className="section__title-wrapper text-center">
                            <span className="section__subtitle-5 uppercase mb-20">Read FAQ</span>
                            <h2 className="section__title">General Questions.</h2>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center wow fadeInUp" data-wow-delay=".5s">
                    <div className="col-xxl-8 col-xl-8 col-lg-10">
                        <div className="fap__style-2">
                            <div className="bd__faq">
                                <div className="accordion" id="accordionExample">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingOne">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                <span>Q.</span> How do I get started with imExchange?
                                            </button>
                                        </h2>
                                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <p>Simply sign up for an account on our website and complete the necessary verification steps. Once your account is approved, you can start exploring startup opportunities and investing through our platform.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingTwo">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                <span>Q.</span> Can I sell my startup stock on imExchange?
                                            </button>
                                        </h2>
                                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <p>Yes, if you own startup stock and wish to sell it, you can list it for sale on imExchange. Our platform provides a marketplace for connecting sellers with potential buyers.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingThree">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                <span>Q.</span> What types of startup companies are available on imExchange?
                                            </button>
                                        </h2>
                                        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <p>We offer a diverse range of startup opportunities across industries such as technology, healthcare, finance, and more. Our curated selection includes high-potential companies with innovative products and services.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingFour">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                <span>Q.</span> How can I contact imExchange for support?
                                            </button>
                                        </h2>
                                        <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <p>You can reach out to our customer support team via email at contact@imexchange.com. Additionally, you can fill out the contact form on our website for assistance.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- FAQ area end --> */}


    </Layout>
  )
}

export default HomePages
