import React from 'react'
import Layout from '../components/Layout/Layout'

const AboutCompany = () => {
  return (
    <Layout title={"About Company Details - ImExChanges"} description={"Dive deep into detailed profiles of innovative startups on ImExchange. Discover comprehensive information about each company, including their mission, vision, team, product/service offerings, and financial performance. Make informed investment decisions with confidence by exploring startup company details on ImExchange"} keywords={"startup company details, company profiles, startup information, ImExchange, innovative startups, invest with confidence"}>
       

       {/* <!-- Use case area start --> */}
       <div className="case__area theme-bg-5 section-space section-is-rounded p-relative">
        <div className="container">
            <div className="row gy-50 align-items-center">
                <div className="col-xxl-12 col-xl-12 col-lg-12">
                     <div className="case__thumb-wrapper p-relative z-index-11 wow fadeInLeft" data-wow-delay=".3s"> 
                        <div className="offer__icon">
                            <span><img src="./imgs/logo/com3.png" alt="img not found" />   </span>
                        </div>
                        <div className="offer__content">
                            <h3>Energies Limited Unlisted Price</h3>
                            <span>Share Price - 50.40</span>
                        </div>

                        <div className="about-company" style={{marginTop: "20px"}}>
                            <h3>About Company</h3>
                            <p style={{fontSize: "15px", marginTop: "10px"}}>
                                The journey of Energies Limited  was the process of coming together of 2 NBFC Micro Finance Institutions, Future Financial Services Private Limited and Disha Microfin Private Limited. Disha Microfin Private Limited received the final license from the RBI under section 22 of the Banking regulation Act, 1949 to launch Banking operations in May 2017. The Bank commenced banking operations on 21st July 2017, with a vision to enable the financial inclusion of the unbanked and underbanked base of the pyramid, mass retail and micro and small enterprise segments with the active participation of the affluent. In the first quarter of FY20, Fincare Small Finance Bank was included in the Second Schedule of the Reserve Bank of India Act, 1934. By virtue of this, our Bank is now a Scheduled Commercial Bank, a status that reflects the highest degree of trust and good governance.

                            </p>
                        </div>

                 <div className="foundamental" style={{display: "flex", gap: "40px", marginTop: "30px"}}>
                    
                    <div className="left">
                        <h3> Fundamental </h3>

                    <ul type="none" style={{marginTop: "20px"}}>
                      <li><strong>Company Name - </strong> Energies Limited Unlisted Price</li>
                      <li><strong>Industry - </strong> Technology</li>
                      <li><strong>52 Week High - </strong> ₹65</li>
                      <li><strong>52 Week Low - </strong> ₹40</li>
                      <li><strong>FACE VALUE/SHARE - </strong> ₹10.0</li>
                      <li><strong>BOOK VALUE/SHARE - </strong> ₹59.5</li>
                      <li><strong>MARKET CAP(Rs.Million) -</strong>49725.0</li>
                    </ul>
                    </div>

                    <div className="right" style={{marginTop: "50px"}}>
                        
                    <ul type="none">
                      <li><strong>DEBT/EQUITY  - </strong> 8.5</li><br />
                      <li><strong>DIVIDEND % (ON CMP)  - </strong> 0.0%</li><br />
                      <li><strong>RETURN ON TOTAL ASSETS  - </strong> 2.2%</li><br />
                      <li><strong>RETURN ON EQUITY  - </strong> 21.2%</li>
                    </ul>
                    </div>
                           
                 </div>               
                 
                </div>
              
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Use case area end --> */}
    
    <div className="case__area theme-bg-5 section-space section-is-rounded p-relative">
        <h3 style={{textAlign: "center"}}>PROFIT & LOSS SUMMARY <span>(All Amount in INR)</span></h3>
     <div className="container">
       
         <div className="row">
           
            <div className="col-xxl-12 col-xl-12 col-lg-12">
                <table className="table table-responsive. " style={{marginTop: "30px", color: "white"}}>
                    <thead>
                      <tr>
                        <th scope="col">PROFIT AND LOSS</th>
                        <th scope="col">2024</th>
                        <th scope="col">2023</th>
                        <th scope="col">2022</th>
                        <th scope="col">2021</th>
                        <th scope="col">2020</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">REVENUE</th>
                        <td>19234</td>
                        <td>12157</td>
                        <td>13784</td>
                        <td>16477</td>
                        <td>12157</td>
                      </tr>
                      <tr>
                        <th scope="row">EXPENSE</th>
                        <td>18672</td>
                        <td>16388</td>
                        <td>12653</td>
                        <td>10723</td>
                        <td>5730</td>
                      </tr>
                      <tr>
                        <th scope="row">OTHER COST</th>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                      </tr>
                      <tr>
                        <th scope="row">TAX EXPENSE</th>
                        <td>1036</td>
                        <td>89</td>
                        <td>1131</td>
                        <td>1435 </td>
                        <td>1020</td>
                      </tr>
                      <tr>
                        <th scope="row">INCOME (NET OF TAXES)</th>
                        <td>2789</td>
                        <td>1899</td>
                        <td>2102</td>
                        <td>817</td>
                        <td>224</td>
                      </tr>
                      <tr>
                        <th scope="row">OUTSTANDING SHARE</th>
                        <td>34</td>
                        <td>32</td>
                        <td>26</td>
                        <td>49</td>
                        <td>45</td>
                      </tr>
                    </tbody>
                  </table>
            </div>
        </div>
     </div>
     </div>


     {/* <!-- balance sheet  --> */}

       
    <div className="case__area theme-bg-5 section-space section-is-rounded p-relative">
        <h3 style={{textAlign: "center"}}>BALANCE SHEET <span>(All Amount in INR)</span></h3>
     <div className="container">
       
         <div className="row">
           
            <div className="col-xxl-12 col-xl-12 col-lg-12">
                <table className="table table-responsive" style={{marginTop: "30px", color: "white"}}>
                    <thead>
                      <tr>
                        <th scope="col">BALANCE SHEET</th>
                        <th scope="col">2024</th>
                        <th scope="col">2023</th>
                        <th scope="col">2022</th>
                        <th scope="col">2021</th>
                        <th scope="col">2020</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">CASH & CASH EQUIVALENT</th>
                        <td>19234</td>
                        <td>12157</td>
                        <td>13784</td>
                        <td>16477</td>
                        <td>12157</td>
                      </tr>
                      <tr>
                        <th scope="row">NON CURRENT ASSET</th>
                        <td>18672</td>
                        <td>16388</td>
                        <td>12653</td>
                        <td>10723</td>
                        <td>5730</td>
                      </tr>
                      <tr>
                        <th scope="row">CURRENT ASSET</th>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                      </tr>
                      <tr>
                        <th scope="row">TOTAL ASSET</th>
                        <td>1036</td>
                        <td>89</td>
                        <td>1131</td>
                        <td>1435 </td>
                        <td>1020</td>
                      </tr>
                      <tr>
                        <th scope="row">EQUITY SHARE CAPITAL</th>
                        <td>1036</td>
                        <td>89</td>
                        <td>1131</td>
                        <td>1435 </td>
                        <td>1020</td>
                      </tr>
                      <tr>
                        <th scope="row">TOTAL ASSET</th>
                        <td>1036</td>
                        <td>89</td>
                        <td>1131</td>
                        <td>1435 </td>
                        <td>1020</td>
                      </tr>
                      <tr>
                        <th scope="row">TOTAL EQUITY</th>
                        <td>1036</td>
                        <td>89</td>
                        <td>1131</td>
                        <td>1435 </td>
                        <td>1020</td>
                      </tr>
                      <tr>
                        <th scope="row">NON CURRENT LIABILITY</th>
                        <td>2232</td>
                        <td>2212</td>
                        <td>89282</td>
                        <td>47643</td>
                        <td>78733</td>
                      </tr>
                      <tr>
                        <th scope="row">CURRENT LIABILITY</th>
                        <td>232</td>
                        <td>223</td>
                        <td>2234</td>
                        <td>89873</td>
                        <td>7665</td>
                      </tr>
                    </tbody>
                  </table>
            </div>
        </div>
     </div>
     </div>

     {/* <!-- balance sheet  --> */}

     {/* <!-- annuly reports  --> */}

     <div className="case__area theme-bg-5 section-space section-is-rounded p-relative">
        <h3 style={{textAlign: "center"}}>CASH FLOW  <span>(All Amount in INR)</span></h3>
        <div className="container">
            <div className="row">
                <div className="col-xxl-12 col-xl-12 col-lg-12">
                    <table className="table table-responsive" style={{marginTop: "30px", color: "white"}}>
                        <thead>
                          <tr>
                            <th scope="col">BALANCE SHEET</th>
                            <th scope="col">2024</th>
                            <th scope="col">2023</th>
                            <th scope="col">2022</th>
                            <th scope="col">2021</th>
                            <th scope="col">2020</th>
                          </tr>
                        </thead>
                        <tbody>
                         
                          <tr>
                            <th scope="row">CASH FLOW
                            </th>
                            <td>1036</td>
                            <td>89</td>
                            <td>1131</td>
                            <td>1435 </td>
                            <td>1020</td>
                          </tr>
                          <tr>
                            <th scope="row">OPERATING ACTIVITY
                            </th>
                            <td>1036</td>
                            <td>89</td>
                            <td>1131</td>
                            <td>1435 </td>
                            <td>1020</td>
                          </tr>
                          <tr>
                            <th scope="row">INVESTING ACTIVITY
                            </th>
                            <td>1036</td>
                            <td>89</td>
                            <td>1131</td>
                            <td>1435 </td>
                            <td>1020</td>
                          </tr>
                          <tr>
                            <th scope="row">FINANCING ACTIVITY
                            </th>
                            <td>2232</td>
                            <td>2212</td>
                            <td>89282</td>
                            <td>47643</td>
                            <td>78733</td>
                          </tr>
                          <tr>
                            <th scope="row">NET CASH</th>
                            <td>232</td>
                            <td>223</td>
                            <td>2234</td>
                            <td>89873</td>
                            <td>7665</td>
                          </tr>
                        </tbody>
                      </table>
                </div>
              
            </div>
            </div>
       </div>




     
    {/* <!-- FAQ area start --> */}
    <section className="faq__area section-space-bottom" style={{marginTop:"20px"}}>
        <div className="container">
            <div className="row section__title-space justify-content-center">
                <div className="col-xxl-5 col-xl-5 col-lg-5">
                    <div className="section__title-wrapper text-center">
                        <span className="section__subtitle-5 uppercase mb-20">Read FAQ</span>
                        <h2 className="section__title">General Questions.</h2>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center wow fadeInUp" data-wow-delay=".5s">
                <div className="col-xxl-8 col-xl-8 col-lg-10">
                    <div className="fap__style-2">
                        <div className="bd__faq">
                            <div className="accordion" id="accordionExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            <span>Q.</span> How do I get started with imExchange?
                                        </button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>Simply sign up for an account on our website and complete the necessary verification steps. Once your account is approved, you can start exploring startup opportunities and investing through our platform.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            <span>Q.</span> Can I sell my startup stock on imExchange?
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>Yes, if you own startup stock and wish to sell it, you can list it for sale on imExchange. Our platform provides a marketplace for connecting sellers with potential buyers.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingThree">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            <span>Q.</span> What types of startup companies are available on imExchange?
                                        </button>
                                    </h2>
                                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>We offer a diverse range of startup opportunities across industries such as technology, healthcare, finance, and more. Our curated selection includes high-potential companies with innovative products and services.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingFour">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                            <span>Q.</span> How can I contact imExchange for support?
                                        </button>
                                    </h2>
                                    <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>You can reach out to our customer support team via email at contact@imexchange.com or by phone at +1 (123) 456-7890. Additionally, you can fill out the contact form on our website for assistance.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!-- FAQ area end -->  */}
   






    </Layout>
  )
}

export default AboutCompany
