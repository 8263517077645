import React, { useState } from 'react'
import Layout from '../components/Layout/Layout'
import toast  from 'react-hot-toast';
import axios from 'axios';
import { useNavigate} from 'react-router-dom';
import config from '../config'

const ForgotPassword = () => {

    const [email , SetEmail]  = useState('');
    const [answer , SetAnswer] = useState('')
    const [newPassword , SetNewPassword] = useState('')
    const navigate = useNavigate()
  
  
     const loginHandler =  async (e) => {
        e.preventDefault()
         try {
           
          const res = await axios.post(`${config.baseURL}/forgot-password`, {email , newPassword , answer});
    
          if(res.data.success)
          {

            setTimeout(() => {
                toast.success(res.data.message, {
                  autoClose: 5000, // Adjust the auto-close time if needed
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                });
              }, 2000);

               navigate('/login')
  
          }else {

            setTimeout(() => {
                toast.error(res.data.message, {
                  autoClose: 5000, // Adjust the auto-close time if needed
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                });
              }, 2000); 
          }
  
         } catch (error) {
          
            setTimeout(() => {
                toast.error('Something went wrong', {
                  autoClose: 5000, // Adjust the auto-close time if needed
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                });
              }, 2000); 
         }
     }
    return (
      <Layout title={"login - imExchanges"}>
  
     <section className='register__area section-space'>
      <div className='container'>
      <div className='login__wrapper text-center mx-auto'>
      <div className="login__intro mb-40">       
          <h3 className="login__title">Reset Your Password</h3>
      </div>
         <form onSubmit={loginHandler}>
              <div class="login__input mb-25">
                  <input type="email" id="form2Example1" value={email}  onChange={(e) => SetEmail(e.target.value)} placeholder='Enter Email' class="form-control" />
              </div>
  
                  <div class="login__input mb-25">
                      <input type="password" id="form2Example2" value={answer} onChange={(e) => SetAnswer(e.target.value)} placeholder='Enter friends Name' class="form-control" />
                  </div>
                  <div class="login__input mb-25">
                      <input type="password" id="form2Example2" value={newPassword} onChange={(e) => SetNewPassword(e.target.value)} placeholder='Enter your new password' class="form-control" />
                  </div>
  
  
                      <button type="submit" class="bd-gradient-btn w-100">Reset Password</button>
  
                    
          </form>
          </div>
          </div>
          </section>
      </Layout>
    )
}

export default ForgotPassword
