import React from 'react'
import Layout from '../components/Layout/Layout'
import { Link } from 'react-router-dom'
const About = () => {
  return (
    <Layout title={"About Us - ImExchange"} description={"Learn more about ImExchange, the leading platform for trading startup shares. Discover our mission, vision, and commitment to empowering investors to participate in the dynamic world of startup investing. Join ImExchange and be part of the journey towards innovation and growth."} keywords={"ImExchange, about us, startup share trading platform, innovative startups, startup investing, buy and sell shares"}>
      

{/* <!-- Breadcrumb area start  --> */}
<div className="breadcrumb__area breadcrumb-space theme-bg-1 valign p-relative z-index-11 fix">
    <div className="breadcrumb__glow">
        <div className="glow-1"></div>
        <div className="glow-2"></div>
    </div>
    <div className="round__shape">
        <img src="./imgs/shapes/cercle.png" alt="img not found" />
    </div>
    <div className="container">
        <div className="row">
            <div className="col-xxl-6 col-xl-6 col-lg-8">
                <div className="breadcrumb__title-wraper">
                    <span className="section__subtitle-7 wow fadeIn mb-20" data-wow-delay=".3s">About us</span>
                    <h2 className="breadcrumb__title wow fadeIn" data-wow-delay=".5s"> <span>imExchange</span> </h2>
                </div>
            </div>
        </div>
    </div>
</div>
{/* <!-- Breadcrumb area start  --> */}

{/* <!-- Our Mission area start --> */}
<section className="mission__area o-xs section-space">
    <div className="container">
        <div className="row gy-50 align-items-center">
            <div className="col-xxl-6 col-xl-6 col-lg-6">
                <div className="mission__thumb wow fadeInLeft animated" data-wow-delay=".6s">
                    <img src="./imgs/about/mission.png" alt="img not found" />
                </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6">
                <div className="mission__content wow fadeInRight animated" data-wow-delay=".6s">
                    <div className="section__title-wrapper">
                        <span className="section__subtitle-7 mb-20">About US</span>
                        <h2 className="section__title mb-20">Welcome to imExchange</h2>
                    </div>
                    <p>the premier platform for buying and selling startup company stock. At imExchange, we are passionate about connecting investors with exciting opportunities in the dynamic world of startups.</p>

                    <div className="btn__wrapper">
                        <Link className="bd-gradient-btn" to="/Contact">Know More<span><i
                         className="fa-regular fa-angle-right"></i></span></Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
{/* <!-- Our Mission area end --> */}






  {/* <!-- Our Mission area start --> */}
  <section className="mission__area o-xs section-space">
    <div className="container">
        <div className="row gy-50 align-items-center">
            <div className="col-xxl-6 col-xl-6 col-lg-6">
                <div className="mission__content wow fadeInRight animated" data-wow-delay=".6s">
                    <div className="section__title-wrapper">
                        <span className="section__subtitle-7 mb-20">Our Mission </span>
                        <h2 className="section__title mb-20">Our mission at imExchange</h2>
                    </div>
                    <p>Our mission at imExchange is to democratize access to startup investments, empowering both seasoned investors and newcomers alike to participate in the growth of innovative companies. We believe that everyone should have the opportunity to support and benefit from the success of startups.
                    </p>

                    <div className="btn__wrapper">
                        <Link className="bd-gradient-btn" href="/Contact">Know More<span><i
                         className="fa-regular fa-angle-right"></i></span></Link>
                    </div>
                </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6">
                <div className="mission__thumb wow fadeInLeft animated" data-wow-delay=".6s">
                    <img src="./imgs/about/mission.png" alt="img not found" />
                </div>
            </div>

        </div>
    </div>
</section>
{/* <!-- Our Mission area end --> */}




<section className="mission__area o-xs section-space">
    <div className="container">
        <div className="row gy-50 align-items-center">
            <div className="col-xxl-6 col-xl-6 col-lg-6">
                <div className="mission__content wow fadeInRight animated" data-wow-delay=".6s">
                    <div className="section__title-wrapper">
                        <span className="section__subtitle-7 mb-20">About US</span>
                        <h2 className="section__title mb-20">What We Offer   </h2>
                    </div>
                    <p>imExchange provides a secure and user-friendly marketplace where investors can discover, research, and invest in a diverse range of startup companies. Whether you're looking to diversify your portfolio, support emerging technologies, or simply explore new investment opportunities, imExchange is your gateway to the startup ecosystem.                            </p>

                    <div className="btn__wrapper">
                        <Link className="bd-gradient-btn" to="/Contact">Know More<span><i
                         className="fa-regular fa-angle-right"></i></span></Link>
                    </div>
                </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6">
                <div className="mission__content wow fadeInRight animated" data-wow-delay=".6s">
                    <div className="section__title-wrapper">
                        <span className="section__subtitle-7 mb-20">About US</span>
                        <h2 className="section__title mb-20">Our Team    </h2>
                    </div>
                    <p>At imExchange, we have a dedicated team of professionals with expertise in finance, technology, and startups. We are committed to providing exceptional service and support to our users every step of the way.                            </p>

                    <div className="btn__wrapper">
                        <Link className="bd-gradient-btn" to="/Contact">Know More<span><i
                         className="fa-regular fa-angle-right"></i></span></Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
{/* <!-- Our Mission area end --> */}


{/* <!-- Revolution area start --> */}
<section className="revolution__area section-space-bottom wow fadeIn" data-wow-delay=".6s">
    <div className="container">
        <div className="revolution__wrapper">
            <div className="revolution__shape"></div>
            <div className="row gy-5 align-items-center">
                <div className="col-xxl-5 col-xl-6 col-lg-6">
                    <div className="revolution__intro section__title-space">
                        <h3>Revolutionizing trade <span className="text-paragraph">Through Online Innovation</span> </h3>
                    </div>
                </div>
            </div>
            <div className="revolution__grid">
                <div className="revolution__item text-center">
                    <div className="revolution__content">
                        <div className="revolution__number">
                            <h2><span className="counter">5</span>+</h2>
                        </div>
                        <p>Years of Experience</p>
                    </div>
                </div>
                <div className="revolution__item text-center is-mainly-red">
                    <div className="revolution__content">
                        <div className="revolution__number">
                            <h2><span className="counter">2</span>+</h2>
                        </div>
                        <p>Years of Experience</p>
                    </div>
                </div>
                <div className="revolution__item text-center is-mainly-blue">
                    <div className="revolution__content">
                        <div className="revolution__number">
                            <h2><span className="counter">36</span>+</h2>
                        </div>
                        <p>Investors Partners</p>
                    </div>
                </div>
                <div className="revolution__item text-center is-mainly-pink">
                    <div className="revolution__content">
                        <div className="revolution__number">
                            <h2><span className="counter">1</span>m+</h2>
                        </div>
                        <p>Global User Worldwide</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
{/* <!-- Revolution area end --> */}

{/* <!-- Our Value area start --> */}
<section className="our-values__area theme-bg-5 section-rounded-60 section-space">
    <div className="container">
        <div className="row gy-50">
            <div className="col-xxl-5 col-xl-5 col-lg-5">
                <div className="our-values__content-wrapper wow fadeIn" data-wow-delay=".3s">
                    <div className="section__title-wrapper">
                        <span className="section__subtitle-7 mb-20">Our values</span>
                        <h2 className="section__title mb-25">Why Choose Us                                </h2>
                    </div>
                    <p className="mb-0">This concise version highlights the key reasons why users should choose your platform for their startup investment needs. Feel free to Contact Us</p>
                    <div className="btn__wrapper">
                        <Link className="bd-gradient-btn" to="/Contact">Contact Us<span><i
                         className="fa-regular fa-angle-right"></i></span></Link>
                    </div>
                </div>
            </div>
            <div className="col-xxl-7 col-xl-7 col-lg-7">
                <div className="row g-5">
                    <div className="col-lg-6 col-md-6 col-sm-6">
                        <div className="our-values__item wow fadeIn" data-wow-delay=".4s">
                            <div className="our-values__icon">
                                <span>
                         <svg width="40" height="40" viewBox="0 0 40 40" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                               d="M35.7703 13.0094L35.0743 12.5077L35.3424 11.6927C35.8861 10.0396 35.6018 8.21471 34.582 6.81108C33.5621 5.40745 31.9152 4.57519 30.1742 4.57933L29.3163 4.58238L29.0541 3.76551C28.5223 2.10865 27.2197 0.799402 25.5695 0.263185C23.9194 -0.272797 22.0959 0.0205869 20.6917 1.04841L19.9995 1.55517L19.3072 1.04841C17.9031 0.0205869 16.0797 -0.273032 14.4295 0.263185C12.7793 0.799402 11.4767 2.10865 10.9449 3.76551L10.6827 4.58238L9.8248 4.57933C8.08583 4.57214 6.43694 5.40745 5.41701 6.81108C4.39708 8.21471 4.11284 10.0397 4.65655 11.6927L4.92462 12.5077L4.22871 13.0094C2.81726 14.0272 1.97461 15.6707 1.97461 17.4058C1.97461 19.1409 2.81726 20.7844 4.22871 21.8021L4.92462 22.3039L4.65655 23.1189C4.11291 24.7719 4.39716 26.5968 5.41701 28.0004C6.43303 29.3988 8.07247 30.2323 9.80527 30.2323H9.82472L10.6827 30.2291L10.9449 31.0461C11.4767 32.7029 12.7793 34.0122 14.4295 34.5484C16.0797 35.0845 17.9031 34.791 19.3072 33.7631L19.9995 33.2563L20.6917 33.7631C22.0958 34.791 23.9193 35.0845 25.5695 34.5484C27.2197 34.0122 28.5223 32.7029 29.0541 31.0461L29.3163 30.2291L30.1742 30.2323H30.1937C31.9267 30.2323 33.5659 29.3989 34.582 28.0004C35.6018 26.5967 35.8861 24.7718 35.3424 23.1189L35.0743 22.3039L35.7703 21.8021C37.1817 20.7844 38.0244 19.1409 38.0244 17.4058C38.0244 15.6707 37.1817 14.0272 35.7703 13.0094ZM19.9995 30.5264C12.7647 30.5264 6.87885 24.6405 6.87885 17.4059C6.87885 10.1712 12.7647 4.28524 19.9995 4.28524C27.2342 4.28524 33.1201 10.1711 33.1201 17.4059C33.1201 24.6406 27.2342 30.5264 19.9995 30.5264Z"
                               fill="white" />
                            <path
                               d="M19.9993 6.62915C14.057 6.62915 9.22266 11.4635 9.22266 17.4058C9.22266 23.3481 14.057 28.1824 19.9993 28.1824C25.9416 28.1824 30.776 23.3481 30.776 17.4058C30.776 11.4635 25.9416 6.62915 19.9993 6.62915ZM17.9416 22.9847L13.3854 18.4285L15.0428 16.7711L17.9416 19.6699L24.5679 13.0436L26.2253 14.701L17.9416 22.9847Z"
                               fill="white" />
                            <path
                               d="M26.2945 36.7778C25.9794 36.8802 25.6598 36.961 25.3379 37.0216L26.7791 40H33.0278L30.1304 34.0121C29.1796 35.2948 27.8444 36.2742 26.2945 36.7778Z"
                               fill="white" />
                            <path
                               d="M9.87009 34.012L6.97266 39.9999H13.2214L14.6628 37.0209C14.3409 36.9604 14.0212 36.8802 13.706 36.7777C12.1562 36.2742 10.8209 35.2948 9.87009 34.012Z"
                               fill="white" />
                         </svg>
                      </span>
                            </div>
                            <div className="our-values__content">
                                <h3>Curated Selection</h3>
                                <p>We carefully curate our list of startup companies to offer investors high-quality investment opportunities across various industries.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6">
                        <div className="our-values__item is-mainly-red wow fadeIn" data-wow-delay=".5s">
                            <div className="our-values__icon">
                                <span>
                         <svg width="40" height="40" viewBox="0 0 40 40" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M19.6895 13.7992H25.5451V40H19.6895V13.7992Z" fill="white" />
                            <path d="M3.29102 29.6122H9.14664V40H3.29102V29.6122Z" fill="white" />
                            <path d="M11.4902 21.7057H17.3458V40H11.4902V21.7057Z" fill="white" />
                            <path d="M30.5242 0L24.3398 6.18438H27.8888V40H33.1597V6.18438H36.7086L30.5242 0Z"
                               fill="white" />
                         </svg>
                      </span>
                            </div>
                            <div className="our-values__content">
                                <h3>Transparency</h3>
                                <p>Transparency is at the core of our platform. We provide comprehensive information about each company, enabling investors to make informed decisions.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6">
                        <div className="our-values__item is-mainly-pink wow fadeIn" data-wow-delay=".6s">
                            <div className="our-values__icon">
                                <span>
                         <svg width="40" height="40" viewBox="0 0 40 40" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                               d="M31.25 3.75H8.75C5.25 3.75 2.5 6.5 2.5 10V22.5C2.5 26 5.25 28.75 8.75 28.75C9.5 28.75 10 29.25 10 30V35C10 35.5 10.25 35.875 10.75 36.125C10.875 36.25 11 36.25 11.25 36.25C11.5 36.25 11.75 36.125 12 36L20.625 29.5C21.25 29 22.125 28.75 22.875 28.75H31.25C34.75 28.75 37.5 26 37.5 22.5V10C37.5 6.5 34.75 3.75 31.25 3.75ZM28.75 21.25H11.25C10.5 21.25 10 20.75 10 20C10 19.25 10.5 18.75 11.25 18.75H28.75C29.5 18.75 30 19.25 30 20C30 20.75 29.5 21.25 28.75 21.25ZM28.75 13.75H11.25C10.5 13.75 10 13.25 10 12.5C10 11.75 10.5 11.25 11.25 11.25H28.75C29.5 11.25 30 11.75 30 12.5C30 13.25 29.5 13.75 28.75 13.75Z"
                               fill="white" />
                         </svg>
                      </span>
                            </div>
                            <div className="our-values__content">
                                <h3>Security</h3>
                                <p>Your security is our top priority. We employ robust security measures to protect your investments and personal information.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6">
                        <div className="our-values__item is-mainly-blue wow fadeIn" data-wow-delay=".7s">
                            <div className="our-values__icon">
                                <span>
                         <svg width="40" height="40" viewBox="0 0 40 40" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_972_1605)">
                               <path
                                  d="M13.0728 25.9748C13.0728 24.4149 14.113 23.0934 15.5362 22.6674C15.2661 22.1691 15.1227 21.6085 15.1227 21.0259C15.1227 20.4294 15.2739 19.8564 15.5566 19.3494C15.0067 19.5223 14.4216 19.6159 13.8148 19.6159C10.6109 19.6159 8.01367 17.0186 8.01367 13.8148C8.01367 10.6109 10.6109 8.01367 13.8148 8.01367C17.0186 8.01367 19.6159 10.6109 19.6159 13.8148C19.6159 14.4217 19.5223 15.0067 19.3494 15.5566C19.8564 15.2739 20.4294 15.1227 21.0259 15.1227C21.6085 15.1227 22.1691 15.2661 22.6674 15.5362C23.0934 14.113 24.4149 13.0728 25.9748 13.0728H27.6295V11.7933C27.6295 11.1461 27.1048 10.6214 26.4577 10.6214H24.3692C24.1582 9.91773 23.8788 9.24367 23.5387 8.60695L25.0127 7.13289C25.4704 6.67523 25.4704 5.93328 25.0127 5.47562L22.154 2.61687C21.6963 2.15922 20.9544 2.15922 20.4967 2.61687L19.0297 4.08375C18.391 3.73992 17.7145 3.45766 17.008 3.24406V1.17187C17.008 0.524687 16.4834 0 15.8362 0H11.7933C11.1461 0 10.6214 0.524687 10.6214 1.17187V3.24398C9.915 3.45758 9.23844 3.73992 8.59976 4.08367L7.13289 2.61687C6.67523 2.15922 5.93328 2.15922 5.47562 2.61687L2.61687 5.47562C2.15922 5.93328 2.15922 6.67523 2.61687 7.13289L4.09094 8.60695C3.7507 9.24367 3.47141 9.91773 3.26039 10.6214H1.17187C0.524687 10.6214 0 11.1461 0 11.7933V15.8362C0 16.4834 0.524687 17.008 1.17187 17.008H3.27414C3.48687 17.7061 3.76687 18.3748 4.10695 19.0065L2.61687 20.4966C2.15922 20.9542 2.15922 21.6962 2.61687 22.1538L5.47562 25.0126C5.93328 25.4702 6.67523 25.4702 7.13289 25.0126L8.63016 23.5153C9.25992 23.8518 9.92609 24.1287 10.6214 24.339V26.4575C10.6214 27.1047 11.1461 27.6294 11.7933 27.6294H13.0728V25.9748Z"
                                  fill="white" />
                               <path
                                  d="M39.9993 29.4417V25.9748C39.9993 25.363 39.5033 24.867 38.8915 24.867H37.0984C36.9106 24.2409 36.6621 23.6412 36.3595 23.0747L37.6249 21.8092C38.0576 21.3765 38.0576 20.6751 37.6249 20.2425L35.1734 17.791C34.7408 17.3583 34.0394 17.3583 33.6067 17.791L32.3477 19.0501C31.7795 18.7441 31.1775 18.493 30.5489 18.303V16.5244C30.5489 15.9126 30.0529 15.4166 29.4411 15.4166H25.9742C25.3623 15.4166 24.8664 15.9126 24.8664 16.5244V18.303C24.2379 18.493 23.6359 18.7442 23.0677 19.0501L21.8086 17.791C21.3759 17.3583 20.6745 17.3583 20.2419 17.791L17.7904 20.2425C17.3577 20.6751 17.3577 21.3765 17.7904 21.8092L19.0559 23.0747C18.7531 23.6412 18.5046 24.2409 18.3169 24.867H16.5238C15.912 24.867 15.416 25.363 15.416 25.9748V29.4417C15.416 30.0536 15.912 30.5495 16.5238 30.5495H18.3291C18.5184 31.1706 18.7676 31.7656 19.0702 32.3276L17.7904 33.6074C17.3577 34.0401 17.3577 34.7415 17.7904 35.1741L20.2419 37.6256C20.6745 38.0583 21.3759 38.0583 21.8086 37.6256L23.0947 36.3395C23.655 36.6389 24.2477 36.8854 24.8664 37.0724V38.8923C24.8664 39.5041 25.3624 40.0001 25.9742 40.0001H29.4411C30.053 40.0001 30.5489 39.5041 30.5489 38.8923V37.0724C31.1676 36.8854 31.7603 36.6389 32.3206 36.3395L33.6067 37.6256C34.0394 38.0583 34.7408 38.0583 35.1734 37.6256L37.6249 35.1741C38.0576 34.7415 38.0576 34.0401 37.6249 33.6074L36.3452 32.3276C36.6477 31.7656 36.8969 31.1707 37.0862 30.5495H38.8915C39.5033 30.5495 39.9993 30.0536 39.9993 29.4417ZM28.0314 32.6532C25.0444 32.8441 22.5716 30.3714 22.7626 27.3844C22.9204 24.9154 24.9147 22.9211 27.3837 22.7633C30.3707 22.5723 32.8434 25.045 32.6525 28.0321C32.4947 30.5011 30.5004 32.4954 28.0314 32.6532Z"
                                  fill="white" />
                            </g>
                            <defs>
                               <clipPath id="clip0_972_1605">
                                  <rect width="40" height="40" fill="white" />
                               </clipPath>
                            </defs>
                         </svg>
                      </span>
                            </div>
                            <div className="our-values__content">
                                <h3>User-Friendly Experience</h3>
                                <p>Our platform is designed to be intuitive and easy to navigate, making it simple for investors to buy and sell startup stock with confidence.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
{/* <!-- Our Values area end --> */}











    </Layout>
  )
}

export default About
