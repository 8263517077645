import React from 'react'
import { NavLink  ,Link, } from 'react-router-dom'
import { useAuth } from '../../context/auth'
// import toast from 'react-hot-toast'
import '../../index.css'


const Header = () => {

     const [auth] = useAuth();
 
    //  const logoutHandler = () => {

    //     setAuth({
    //         ...auth,
    //         user:null,
    //         token:''
    //     })
        
    //     setTimeout(() => {
    //         toast.success('logout Successfully', {
    //             autoClose: 8000, // Adjust the auto-close time if needed
    //             hideProgressBar: false,
    //             pauseOnHover: true,
    //             draggable: true,
    //         });
    //     }, 2000); 
        
    //     localStorage.removeItem('auth')
       
    //  }


  return (
    <>

  
    {/* <!-- Preloder start --> */}

    {/* <!-- Backtotop start --> */}
    <div className="backtotop-wrap cursor-pointer">
        <svg className="backtotop-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
            <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
        </svg>
    </div>
    {/* <!-- Backtotop end --> */}

    {/* <!-- Offcanvas area start --> */}
    <div className="fix">
        <div className="offcanvas__area">
            <div className="offcanvas__wrapper">
                <div className="offcanvas__content">
                    <div className="offcanvas__top d-flex justify-content-between align-items-center">
                        <div className="offcanvas__logo">
                            <Link to="/">
                                <img src="./imgs/logo/logo.png"  alt="logo not found" />
                            </Link>
                        </div>
                        <div className="offcanvas__close">
                            <button className="offcanvas-close-icon animation--flip">
                                <span className="offcanvas-m-lines">
                              <span className="offcanvas-m-line line--1"></span><span className="offcanvas-m-line line--2"></span><span className="offcanvas-m-line line--3"></span>
                                </span>
                            </button>
                        </div>
                    </div>
                    <div className="mobile-menu fix"></div>
                    <div className="offcanvas__social">
                        <h3 className="offcanvas__title mb-20">Subscribe & Follow</h3>
                        <ul>
                            <li><Link to ="https://www.facebook.com/ImExchangePreIPO/" target='_blank'><i className="fab fa-facebook-f"></i></Link></li>
                            <li><Link to ="https://twitter.com/exchange_im" target='_blank'><i className="fab fa-twitter"></i></Link></li>
                            <li><Link to ="https://www.instagram.com/imexchange2/" target='_blank'><i className="fab fa-instagram"></i></Link></li>
                            <li><Link to ="https://www.linkedin.com/company/imexchange/" target='_blank'><i className="fab fa-linkedin"></i></Link></li>
                        </ul>
                    </div>
                    <div className="offcanvas__btn">
                        <div className="header__btn-wrap">
                            <Link className="bd-gradient-btn" to ="/Contact">Get Started</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="offcanvas__overlay"></div>
    <div className="offcanvas__overlay-white"></div>
    {/* <!-- Offcanvas area start --> */}

     {/* <!-- Header area start --> */}
     <header>
        <div id="header-sticky" className="header__area">
            <div className="container">
                <div className="mega__menu-wrapper p-relative">
                    <div className="header__main">
                        <div className="header__left">
                            <div className="header__logo">
                                <Link to="/">
                                    <img className="logo__white" src="./imgs/logo/logo.png" width="40px" height="30px" alt="logo not found" />
                                </Link>
                            </div>
                        </div>
                        <div className="header__middle">
                            <div className="mean__menu-wrapper d-none d-lg-block">
                                <div className="main-menu">
                                    <nav id="mobile-menu">
                                                        <ul>
                                                            <li><NavLink to="/">Home</NavLink></li>
                                                            <li><NavLink to="/About">About Us</NavLink></li>
                                                            <li><NavLink to="/Unlisted-Share">Unlisted Share</NavLink></li>

                                                            <li><NavLink to="/Buy-Share">Buy</NavLink></li>
                                                            <li><NavLink to="/Sell-Share">Sell</NavLink></li>
                                                            <li><NavLink to="/Contact">Contact</NavLink></li>
                                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>

                        <div className="header__right">
                            <div className="header__action d-flex align-items-center">
                                <div className="header__btn-wrap d-none d-sm-inline-flex">
                                {
                                    !auth.user?.role === true ? (<>
                                        {/* <Link  className="bd-gradient-btn"  to="/register">Register<span><i className="fa-regular fa-angle-right"></i></span></Link> */}
                                        <Link  className="bd-gradient-btn"  to="/login">Login<span><i className="fa-regular fa-angle-right"></i></span></Link>
                                    </>) :  (<>
                                        <Link  className="bd-gradient-btn"  to="/dashboard/admin">Dashboard<span><i className="fa-regular fa-angle-right"></i></span></Link>

                                      
                                    </>)
                                }
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    {/* Header area end  */}
     
 










    </>
  )
}

export default Header
