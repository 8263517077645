import { useState , useEffect } from "react";
import {useAuth} from '../../context/auth'
import {Outlet} from 'react-router-dom'
import axios from 'axios'
import Spinner from '../Spinner'
import config from '../../config'

export default function privateRoutes(){
  
  //eslint-disable-next-line
  const [ok ,setOk] = useState(false)
    //eslint-disable-next-line
  const [auth, setAuth] = useAuth()

  //eslint-disable-next-line
   useEffect(() => {
      
      //eslint-disable-next-line
    const authChecks = async () => {
      const res = await axios.get(`${config.baseURL}/api/user`)

      if(res.data.ok)
      {
        setOk(true)
      }else{
        setOk(false)
      }

    }
    if(auth?.token) authChecks()

   }, [auth?.token])

   return ok ?   <Outlet/> :<Spinner />
}
