import {  BrowserRouter,Routes,Route } from "react-router-dom";
import HomePages from "./pages/HomePages";
import About from "./pages/About";
import Contact from "./pages/Contact";
import UnlistedShare from "./pages/UnlistedShare";
import PagesNotFound from "./pages/PagesNotFound";
import AboutCompany from "./pages/AboutCompany"; 
import Login from './pages/Login'
// import PrivateRoutes from './components/Routes/PrivateRoutes'
import BuyStock from './pages/BuyStock'
import SellStocks from './pages/SellStocks'
import AdminRoute from './components/Routes/PrivateRoutes'
import ForgotPassword from "./pages/ForgotPassword";
import {AdminDashboard} from "./Admin/AdminDashboard";
import {ContactData} from './Admin/ContactData'
import {BuyData} from './Admin/Buydata'
import {SellData} from './Admin/SellData'

function App() {
  return(
    <>
    <BrowserRouter>
       <Routes>
 
      <Route path="/" element={<HomePages/>} />
      <Route path="/About" element={<About/>} />
      <Route path="/Unlisted-Share" element={<UnlistedShare/>} />
      <Route path="/About-Company-Details" element={<AboutCompany/>} />
       <Route path='/buy-Share' element={<BuyStock/>} />
       <Route  path='/sell-Share' element={<SellStocks />} />

       <Route path="/dashboard" element={<AdminRoute />}>
        <Route path="Admin" element={<AdminDashboard />} />
        <Route path="Contact-data" element={<ContactData />} />
        <Route path="Buy-data" element={<BuyData />} />
        <Route path="Sell-data" element={<SellData />} />
       </Route>
      <Route path="/forgot-password" element={<ForgotPassword/>} />
      <Route path="/Contact" element={<Contact/>} />
      <Route path="/login" element={<Login/>}  />
      <Route path="*" element={<PagesNotFound/>} />

      
       </Routes>
  
  
    </BrowserRouter>
     
    </>
  )
}

export default App;
