import React from 'react'
import { Link, NavLink } from 'react-router-dom'

const Footer = () => {
  return (
    <>
         {/* <!-- Footer area start --> */}
    <footer>
        <div className="footer__area-6">
            <div className="container">
                <div className="footer__grid">
                    <div className="footer__widget-4">
                        <div className="footer__logo">
                            <Link to="index.html">
                                <img src="./imgs/logo/logo.png" alt="logo not found" />
                            </Link>
                        </div>
                    </div>
                    <div className="footer__widget-4">
                        <div className="footer__widget-title">
                            <h5>Use Cases</h5>
                        </div>
                        <div className="footer__link">
                            <ul>
                                <li><NavLink to="/">Home</NavLink></li> 
                                <li><NavLink to="/About">About Us</NavLink></li>
                            
                            </ul>
                        </div>
                    </div>
                   
                    <div className="footer__widget-4">
                        <div className="footer__widget-title">
                            <h5>Links</h5>
                        </div>
                        <div className="footer__link">
                            <ul>
                                <li><NavLink to="/Buy-Share">Buy</NavLink></li>
                                <li><NavLink to="/Sell-Share">Sell</NavLink></li>
                            </ul>
                        </div>
                    </div>
                    <div className="footer__widget-4">
                        <div className="footer__widget-title">
                            <h5>  Unlisted Share </h5>
                        </div>
                        <div className="footer__link">
                            <ul>
                                <li><NavLink to="/login">login</NavLink></li>
                                <li><NavLink to="/Unlisted-Share">Share</NavLink></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="footer__bottom-area">
            <div className="container">
                <div className="footer__bottom-2 bt-0">
                    <div className="footer__copyright">
                        <p>Copyright © 2024 <Link to="/">imExchange</Link></p>
                    </div>
                    <div className="footer__social">
                        <Link to="https://www.facebook.com/ImExchangePreIPO/"><i className="fa-brands fa-facebook"></i></Link>
                        <Link to="https://twitter.com/exchange_im"><i className="fa-brands fa-twitter"></i></Link>
                        <Link to="https://www.linkedin.com/company/imexchange"><i className="fa-brands fa-linkedin"></i></Link>
                        <Link to="https://www.instagram.com/imexchange2/"><i className="fa-brands fa-instagram"></i></Link>
                    </div>
                    <div className="footer__conditions">
                        <ul>
                            <li><Link to="/Contact">Contact Us</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    </>
  )
}

export default Footer
